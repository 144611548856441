<!-- product -->
<template>
    <div class="product">
        <header class="header">
            <div class="header-info">
                <h2>协同治理平台</h2>
                <p>
                    正确树立在党组织的核心引领理念，促使各方在动态中处理各种关系，维持治理结构的平衡。
                    让政企民真正形成“三位一体”基层治理模式，同向发力解决问题，形成齐抓共管的社会治理新局面。
                </p>
                <div class="info-btn">
									  <span><a href="#applyContact">预约试用</a></span>
                    <span @click="qrCode">联系我们</span>
                </div>
            </div>
            <div class="header-img">
                <img v-lazy="require('../../assets/product/banner2-pc.png')" alt="" />
            </div>
        </header>
        <!-- 功能跳转开始 -->
        <div class="feature-list">
            <ul>
                <li v-for="(item, index) in featureList" :key="index" @click="featureListClick(index)">
                    <a :href="item.id" :class="item.isActive ? 'isActive' : ''">{{ item.name }}</a>
                </li>
            </ul>
        </div>
        <!-- 功能跳转结束 -->
        <!-- 功能区内容开始 -->
        <div class="list-content">
            <section class="listFunc">
                <a name="listFunc" style="position: relative; top: -4.5rem"></a>
                <h2>产品特色</h2>
                <div class="listFunc-content" v-for="(item, index) in listFuncContentList" :key="index">
                    <img v-lazy="item.img" alt="" />
                    <div class="info">
                        <div class="title">
                            <img :src="item.icon" alt="" />
                            <h4>{{ item.h4 }}</h4>
                        </div>
                        <p>{{ item.details }}</p>
                    </div>
                </div>
                <div class="more">
                    <div class="info">
                        <h4>如需了解更多功能，请预约线下现场演示或直接联系我们</h4>
                        <p>可根据具体场景，具体需求个性化定制功能</p>
                    </div>
                    <div class="info-btn">
											<span><a style="color: #1976e1" href="#applyContact">预约试用</a></span>
                      <span @click="qrCode()">联系我们</span>
                    </div>
                </div>
            </section>
            <section class="listProduct">
                <a name="listProduct" style="position: relative; top: -4.5rem"></a>
                <h2>应用场景</h2>
                <div class="content">
                    <div class="img"></div>
                    <p>
                        助力高等教育院校在“互联网+教育管理应用”、智慧校园、平安校园等相关系统建设，以及场景化管理方面建立更出色的管理能力，推动“智能教育”的发展。助力高等教育院校在“互联网+教育管理应用”、智慧校园、平安校园等相关系统建设，以及场景化管理方面建立更出色的管理能力，推动“智能教育”的发展。
                    </p>
                </div>
            </section>
            <section class="listCase">
                <a name="listCase" style="position: relative; top: -4.5rem"></a>
                <h2>相关案例</h2>
                <div class="carousel">
                    <el-carousel arrow="never">
                        <el-carousel-item class="el-carousel-item" v-for="(item, index) in listCaseList" :key="index">
							<router-link v-for="(item1, index1) in item.children" :key="index1" :to="item1.path">
								<div class="content">
									<h4>{{ item1.title }}</h4>
									<p>{{ item1.details }}</p>
									<div class="info">
										<div
											class="details-numbers"
											v-for="(item2, index2) in item1.children"
											:key="index2"
										>
											<h5>{{ item2.numbers }}</h5>
											<h5>{{ item2.name }}</h5>
										</div>
									</div>
								</div>
							</router-link>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </section>
            <section class="listDownload">
                <a name="listDownload" style="position: relative; top: -4.5rem"></a>
                <h2>产品下载</h2>
                <div class="content">
                    <div class="list" v-for="(item, index) in listDownloadList" :key="index">
                        <img v-lazy="item.img" alt="" />
                        <div class="info">
                            <h4><img :src="item.icon" alt="" />{{ item.title }}</h4>
                            <p>{{ item.details }}</p>
                        </div>
                    </div>
                    <div class="mobile-list">
                        <img src="../../assets/product/a@3x.png" alt="" />
                        <div class="info">
                            <h4><img src="../../assets/product/40@3x.png" alt="" />安卓手机端</h4>
                            <p class="btn" @click="downLoad(0)">立即下载</p>
                        </div>
                    </div>
                    <div class="mobile-list">
                        <img src="../../assets/product/iOS@3x.png" alt="" />
                        <div class="info">
                            <h4><img src="../../assets/product/ios  40@3x.png" alt="" />iOS小程序</h4>
                            <p class="ios">iOS用户可截图二维码，微信扫码直接访问小程序</p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="listProblem">
                <a name="listProblem" style="position: relative; top: -4.5rem"></a>
                <h2>常见问题</h2>
                <div class="content">
                    <ul>
                        <li v-for="(item, index) in listProblemList_teamwork" :key="index" @click="listProblemListClick(index)">
                            <div class="main" :class="item.isOpen ? 'bg' : 'main'">
                                <img :src="item.img" class="No" />
                                <h4>{{ item.title }}</h4>
                                <img
                                    class="more"
                                    src="../../assets/product/opne@3x.png"
                                    alt=""
                                    :class="item.isOpen ? 'rotate' : 'more'"
                                />
                            </div>
                            <div class="details" v-if="item.isOpen">
                                <p>{{ item.details }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
								<div class="tips"></div>
                <!--div class="tips">更多问题请移步<span>帮助中心</span>进行查看</div-->
            </section>
        </div>
        <!-- 功能区内容结束 -->
        <!-- 二维码 -->
        <contactUs ref="contactUs"></contactUs>
        <!-- 移动端二维码 -->
				<apply ref="apply"></apply>
    </div>
</template>

<script>
import apply from "@/components/apply/apply";
import contactUs from "@/components/contactUs/contactUs";
import { myMixins } from "@/utils/mixin/mixin.js";
export default {
    mixins: [myMixins],
    components: { apply, contactUs },
    data() {
        return {
            isqrCode: false, //默认不显示二维码
            isOpenMobile: false,
            screenWidth: Number,
            featureList: [
                { name: "产品功能", isActive: true, id: "#listFunc" },
                { name: "应用场景", isActive: false, id: "#listProduct" },
                { name: "相关案例", isActive: false, id: "#listCase" },
                { name: "产品下载", isActive: false, id: "#listDownload" },
                { name: "常见问题", isActive: false, id: "#listProblem" },
                { name: "预约试用", isActive: false, id: "#applyContact" },
            ], //功能列表跳转
            listFuncContentList: [
                {
                    isOpen: false,
                    img: require("../../assets/product/p2-1.png"),
                    icon: require("../../assets/product/pc2-icon1.png"),
                    h4: "应急事件预警",
                    details:
                        "凡发生自然灾害、事故灾难、公共卫生事件和社会安全事件，可通过文字载体将有关情况及时准确地报送上级党委、政府，并可持续补充详细情况的，有效避免迟报、漏报和瞒报情况的发生。",
                },
                {
                    isOpen: false,
                    img: require("../../assets/product/p2-2.png"),
                    icon: require("../../assets/product/pc2-icon2.png"),
                    h4: "满意度测评",
                    details:
                        "改变传统满意度测评监督不力、流于形式的现象，积极改进监督方式，不断完善监督程序，进一步拓宽监督渠道，努力提高人大监督工作的水平。",
                },
                {
                    isOpen: false,
                    img: require("../../assets/product/p2-3.png"),
                    icon: require("../../assets/product/pc2-icon3.png"),
                    h4: "合同/回款管理",
                    details:
                        "创建报事单、指派成员、申请协助、查看及完成事件提供了员工分析、工作流协同编排、数据资产管理和数据治理等全链路数据加工能力，帮助数据工程师高效构建企业级数据中台架构， 助力企业用数据驱动业务增长。",
                },
                {
                    isOpen: false,
                    img: require("../../assets/product/p2-4.png"),
                    icon: require("../../assets/product/pc2-icon4.png"),
                    h4: "协同定制开发",
                    details:
                        "科学、合理地构建联动工作流程，确保各部门单位之间在全域化管理系统各尽其责、高效履职，通过系统互通的形式，减少服务过程中的摩擦，调动各方工作积极性，及时反馈有利于提升工作的效率、效果和效益。",
                },
            ], //产品特色
        };
    },
    directives: {
        fixed: {
            // inserted 被绑定元素插入父节点时调用
            inserted() {
                let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                document.body.style.cssText += "position:fixed;width:100%;top:-" + scrollTop + "px;";
            },
            // unbind 指令与元素解绑时调用
            unbind() {
                let body = document.body;
                body.style.position = "";
                let top = body.style.top;
                document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
                body.style.top = "";
            },
        },
    },
    computed: {},
    watch: {},
    created() {
        this.screenWidth = window.document.body.clientWidth;
    },
    mounted() {},
    methods: {
        // 功能列表跳转
        featureListClick(e) {
            this.featureList.map((item, index) => {
                item.isActive = e === index ? true : false;
            });
        },
        // 常见问题展开点击
        listProblemListClick(e) {
            this.listProblemList_teamwork.map((item, index) => {
                item.isOpen = e === index ? !item.isOpen : false;
            });
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "product_PC2.scss";
}
@media screen and (max-width: 750px) {
    @import "product_mobile2.scss";
}
</style>
